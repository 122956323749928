import jsPDF from 'jspdf'
import VoucherCornerImage1 from './images/Voucher-Corner-1.jpeg'
import VoucherCornerImage2 from './images/Voucher-Corner-2.jpeg'
import VoucherCornerImage3 from './images/Voucher-Corner-3.jpeg'
import VoucherCornerImage4 from './images/Voucher-Corner-4.jpeg'
import BotchVoucherLeft from './images/Botch-Voucher-Left.jpg'
import BotchVoucherRight from './images/Botch-Voucher-Right.jpg'
import BotchDiscountLeft from './images/Botch-Discount-Left.jpg'
import BotchDiscountRight from './images/Botch-Discount-Right.jpg'
import logoPNG from './images/logo.png'
import leafPNG from './images/leaf.png'
import { Color, Font, PX_TO_MM_RATIO, TextRenderer, createTextRenderer } from './textRenderer'
import QRCode from 'qrcode-svg'
import { Canvg } from 'canvg'
import { VoucherApplication } from '../vouchers/application'
import { Voucher } from '../vouchers/domain'

const LEAF_LENGTH = 22.58823529411764

const addDecorationLeafs = (doc: jsPDF) => {
  doc.addImage(VoucherCornerImage1, 'jpeg', 0, 0, LEAF_LENGTH, LEAF_LENGTH)
  doc.addImage(VoucherCornerImage2, 'jpeg', 0, 297 - LEAF_LENGTH, LEAF_LENGTH, LEAF_LENGTH)
  doc.addImage(VoucherCornerImage3, 'jpeg', 210 - LEAF_LENGTH, 297 - LEAF_LENGTH, LEAF_LENGTH, LEAF_LENGTH)
  doc.addImage(VoucherCornerImage4, 'jpeg', 210 - LEAF_LENGTH, 0, LEAF_LENGTH, LEAF_LENGTH)
}

const addExampleText = (doc: jsPDF) => {
  doc.setFont(Font.AmaticSC, 'normal')
  doc.setFontSize(256)
  doc.setTextColor(Color.Grey60)
  doc.saveGraphicsState()
  doc.setGState(doc.GState({ opacity: 0.2 }))
  doc.text('Example', 74, 243.58899669320806, { angle: 54.43 })
  doc.restoreGraphicsState()
}

const addSlogan = (textRender: TextRenderer) => {
  const relativeTo = { x: 150.5, y: 36.5, width: 295 }
  textRender.create('Sisterhood Massagen', 'center', 0, { font: Font.Licorice, size: 46.19, color: Color.Red, relativeTo })
  textRender.create('von Isabelle Petry', 'center', 58, { font: Font.Licorice, size: 30.8, color: Color.Blue, relativeTo })
}

const addLogo = (doc: jsPDF) => {
  const x = (doc.internal.pageSize.width - 72 * PX_TO_MM_RATIO) / 2
  doc.addImage(logoPNG, x, 154 * PX_TO_MM_RATIO, 72 * PX_TO_MM_RATIO, 72 * PX_TO_MM_RATIO)
}

const addHeaderVoucher = (doc: jsPDF, textRender: TextRenderer, voucher: Voucher) => {
  const relativeTo = { x: 138, width: 320, y: 233 }
  doc.setFont(Font.AmaticSC, 'normal')
  doc.setFontSize(64)
  const title = VoucherApplication.getTitle(voucher)
  const titleLength = doc.getTextWidth(title)
  const titleX = (doc.internal.pageSize.width - titleLength) / 2
  const leftX = titleX - 38.48823529
  const rightX = titleX + titleLength - 18.75176471
  doc.addImage(BotchVoucherLeft, leftX, 341.95 * PX_TO_MM_RATIO, 161.86 * PX_TO_MM_RATIO, 161.86 * PX_TO_MM_RATIO)
  doc.addImage(BotchVoucherRight, rightX, 378.87 * PX_TO_MM_RATIO, 170.25 * PX_TO_MM_RATIO, 170.25 * PX_TO_MM_RATIO)
  textRender.create('Gutschein', 'center', 0, { font: Font.Licorice, size: 100, color: Color.Red, relativeTo })
  textRender.create(`im Wert von ${VoucherApplication.getValueForDisplay(voucher)} für`, 'center', 115, { font: Font.RalewayLight, size: 32, relativeTo })
  textRender.create(title, 'center', 166, { font: Font.AmaticSC, size: 64, color: Color.Blue, relativeTo })
  textRender.create(VoucherApplication.getSubtitle(voucher), 'center', 255, { font: Font.RalewayLight, size: 20, relativeTo })
}

const addHeaderDiscount = (doc: jsPDF, textRender: TextRenderer, voucher: Voucher) => {
  const relativeTo = { x: 138, width: 320, y: 233 }
  doc.setFont(Font.AmaticSC, 'normal')
  doc.setFontSize(64)
  const title = VoucherApplication.getValueForDisplay(voucher)
  const titleLength = doc.getTextWidth(title)
  const titleX = (doc.internal.pageSize.width - titleLength) / 2
  const leftX = titleX - 38.50588235
  const rightX = titleX + titleLength - 16.78588235
  doc.addImage(BotchDiscountLeft, leftX, 327.9 * PX_TO_MM_RATIO, 157.95 * PX_TO_MM_RATIO, 157.95 * PX_TO_MM_RATIO)
  doc.addImage(BotchDiscountRight, rightX, 375.44 * PX_TO_MM_RATIO, 145.12 * PX_TO_MM_RATIO, 145.12 * PX_TO_MM_RATIO)
  textRender.create('Entspannungsbonus', 'center', 0, { font: Font.Licorice, size: 72, color: Color.Red, relativeTo })
  textRender.create('im Wert von', 'center', 95, { font: Font.RalewayLight, size: 32, relativeTo })
  textRender.create(title, 'center', 150, { font: Font.AmaticSC, size: 64, color: Color.Blue, relativeTo })
}

const getLowerPartOffset = (voucher: Voucher) => {
  return voucher.voucherType === 'gift-card' ? 0 : -5
}

const createQRCode = async (link: string) => {
  const qrcode = new QRCode({
    content: link,
    padding: 0,
    width: 300,
    height: 300,
    color: '#333333',
    background: '#ffffff',
    ecl: 'L',
    join: true
  })
  const svgString = qrcode.svg()
  // Create a canvas element
  const canvas = document.createElement('canvas')
  canvas.width = 300 * 4
  canvas.height = 300 * 4

  // Use Canvg to render SVG on the canvas
  const ctx = canvas.getContext('2d')
  if (ctx) {
    const v = await Canvg.fromString(ctx, svgString)
    v.start()

    // Get the image data from the canvas
    return canvas.toDataURL('image/png')
  }
}

const addQRCode = async (doc: jsPDF, textRender: TextRenderer, voucher: Voucher) => {
  const url = process.env.REACT_APP_FE_URL ?? 'https://voucher.sisterhoodmassagen.de'
  const link = `${url}/${VoucherApplication.formatYearAndIndex(voucher.voucherYearIndex, voucher.voucherYear)}#${voucher.voucherCode}`
  const relativeTo = { x: 305.5, width: 140, y: 541 + getLowerPartOffset(voucher) }
  textRender.create(`Dein ${VoucherApplication.getName(voucher)} Digital`, 'center', 0, { font: Font.AmaticSC, size: 16, relativeTo })
  textRender.create('voucher.sisterhoodmassagen.de', 'center', 104, { font: Font.RalewayLight, relativeTo, link })

  const qrCode = await createQRCode(link)
  if (qrCode) {
    doc.addImage(qrCode, 'PNG', 339.5 * PX_TO_MM_RATIO, (565 + getLowerPartOffset(voucher)) * PX_TO_MM_RATIO, 72 * PX_TO_MM_RATIO, 72 * PX_TO_MM_RATIO)
  }
}

const addLeafs = (doc: jsPDF, voucher: Voucher) => {
  const width = 18.18
  const height = 8
  const x = (doc.internal.pageSize.width - width * PX_TO_MM_RATIO) / 2
  const offset = getLowerPartOffset(voucher) * PX_TO_MM_RATIO
  doc.addImage(leafPNG, x, offset + 525 * PX_TO_MM_RATIO, width * PX_TO_MM_RATIO, height * PX_TO_MM_RATIO)
  doc.addImage(leafPNG, x, offset + 663 * PX_TO_MM_RATIO, width * PX_TO_MM_RATIO, height * PX_TO_MM_RATIO)
  doc.addImage(leafPNG, x, offset + 773 * PX_TO_MM_RATIO, width * PX_TO_MM_RATIO, height * PX_TO_MM_RATIO)
}

const addInformation = (textRender: TextRenderer, voucher: Voucher) => {
  const relativeTo = { x: 149.5, width: 140, y: 541 + getLowerPartOffset(voucher) }
  const optionsText = { size: 12, relativeTo }
  const optionsHeading = { ...optionsText, color: Color.Red }
  textRender.create(`${VoucherApplication.getNameShort(voucher)}-Nummer`, 'center', 0, optionsHeading)
  textRender.create(VoucherApplication.formatYearAndIndex(voucher.voucherYearIndex, voucher.voucherYear), 'center', 16, optionsText)

  textRender.create(`${VoucherApplication.getNameShort(voucher)}-Code`, 'center', 42, optionsHeading)
  textRender.create(voucher.voucherCode.replaceAll('-', ' - '), 'center', 58, optionsText)

  textRender.create('Ausstellungsdatum', 'center', 84, optionsHeading)
  textRender.create(VoucherApplication.formatUTCDate(voucher.issueDate), 'center', 100, optionsText)
}

const addInstructions = (textRender: TextRenderer, voucher: Voucher) => {
  const relativeTo = { x: 24, width: 257.5, y: 679 + getLowerPartOffset(voucher) }
  const optionsText = { relativeTo }
  textRender.create(`So nutzt du deinen ${VoucherApplication.getName(voucher)}`, 0, 0, { ...optionsText, font: Font.AmaticSC, size: 16 })
  textRender.create('1.', 4, 26, optionsText)
  textRender.create('Suche dir einen Termin aus:', 12, 26, optionsText)
  textRender.create('a.', 16, 38, optionsText)
  textRender.create('besuche', 24, 38, optionsText)
  const offset1 = textRender.textWidth('besuche ', optionsText)
  textRender.create('calendly.com/sisterhoodmassagen', 24 + offset1, 38, { ...optionsText, color: Color.Red, link: 'https://calendly.com/sisterhoodmassagen' })
  const offset2 = textRender.textWidth('besuche calendly.com/sisterhoodmassagen ', optionsText)
  textRender.create('oder', 24 + offset2, 38, optionsText)
  textRender.create('b.', 16, 50, optionsText)
  textRender.create('scanne den QR Code', 24, 50, optionsText)
  textRender.create('2.', 4, 62, optionsText)
  textRender.create(`Bringe deinen ${VoucherApplication.getName(voucher)} ausgedruckt oder digital mit.`, 12, 62, optionsText)
  textRender.create('3.', 4, 74, optionsText)
  textRender.create('Entspanne dich und genieße die Massage.', 12, 74, optionsText)
}

const addContact = (textRender: TextRenderer, voucher: Voucher) => {
  const relativeTo = { x: 313.5, width: 257.5, y: 679 + getLowerPartOffset(voucher) }
  const optionsText = { relativeTo }
  textRender.create('Kontakt', 0, 0, { ...optionsText, font: Font.AmaticSC, size: 16 })
  textRender.create('Isabelle Petry (Sisterhood Massagen)', 0, 26, optionsText)
  textRender.create('Mobil | Telegram | WhatsApp: 0176 - 61 31 95 73)', 0, 38, optionsText)
  textRender.create('E-Mail:', 0, 50, optionsText)
  const offset1 = textRender.textWidth('E-Mail: ', optionsText)
  textRender.create('info@sisterhoodmassagen.de', offset1, 50, { ...optionsText, link: 'mailto:info@sisterhoodmassagen.de' })
  textRender.create('Internet:', 0, 62, optionsText)
  const offset2 = textRender.textWidth('Internet: ', optionsText)
  textRender.create('www.sisterhoodmassagen.de', offset2, 62, optionsText)
}

const addDisclaimerVoucher = (textRender: TextRenderer, voucher: Voucher) => {
  const relativeTo = { x: 92, width: 411, y: 789 }
  const optionsText = { relativeTo }
  const line1 = [
    { text: 'Dieser Gutschein schenkt dir ', options: {} },
    { text: `bis zum ${VoucherApplication.formatUTCDate(voucher.validUntil)}`, options: { font: Font.RalewaySemibold } },
    { text: ' Momente der Entspannung und des Wohlbefindens bei mir. Er', options: {} }]
  textRender.createLine(line1, 'center', 0, optionsText)
  const line2 = [
    { text: 'ist für ', options: {} },
    { text: 'alle angebotenen Massagen einlösbar', options: { font: Font.RalewaySemibold } },
    { text: '. Weitere Details, die genauen Einlösebedingungen und meine', options: {} }]
  textRender.createLine(line2, 'center', 12, optionsText)
  const line3 = [
    { text: 'Allgemeinen Geschäftsbedingungen findest du auf meiner Webseite ', options: {} },
    { text: 'www.sisterhoodmassagen.de/agb', options: { color: Color.Red, link: 'https://www.sisterhoodmassagen.de/agb' } },
    { text: '.', options: {} }]
  textRender.createLine(line3, 'center', 24, optionsText)
}

const addDisclaimerDiscount = (textRender: TextRenderer, voucher: Voucher) => {
  const relativeTo = { x: 84, width: 427, y: 784 }
  const optionsText = { relativeTo }
  const line1 = [
    { text: 'Dieser Entspannungsbonus schenkt dir ', options: {} },
    { text: `bis zum ${VoucherApplication.formatUTCDate(voucher.validUntil)}`, options: { font: Font.RalewaySemibold } },
    { text: ' Momente der Entspannung und des Wohlbefindens bei', options: {} }]
  textRender.createLine(line1, 'center', 0, optionsText)
  const line2 = [
    { text: 'mir. Er ist für alle angebotenen Massagen einlösbar. Bitte beachte, dass ', options: {} },
    { text: 'pro Massage nur ein Entspannungsbonus', options: { font: Font.RalewaySemibold } }]
  textRender.createLine(line2, 'center', 12, optionsText)
  const line3 = [
    { text: 'eingelöst werden kann und dieser auch mit weiteren Aktionen ', options: {} },
    { text: 'nicht kombinierbar ist', options: { font: Font.RalewaySemibold } },
    { text: '. Weitere Details, die genauen ', options: {} }]
  textRender.createLine(line3, 'center', 24, optionsText)
  const line4 = [
    { text: 'Einlösebedingungen und meine AGBs findest du auf meiner Webseite ', options: {} },
    { text: 'www.sisterhoodmassagen.de/agb', options: { color: Color.Red, link: 'https://www.sisterhoodmassagen.de/agb' } },
    { text: '.', options: {} }]
  textRender.createLine(line4, 'center', 36, optionsText)
}

const create = async (voucher: Voucher) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4'
  })
  addDecorationLeafs(doc)
  const textRenderer = createTextRenderer(doc)
  addSlogan(textRenderer)
  addLogo(doc)
  if (voucher.voucherType === 'gift-card') {
    addHeaderVoucher(doc, textRenderer, voucher)
  } else {
    addHeaderDiscount(doc, textRenderer, voucher)
  }
  addInformation(textRenderer, voucher)
  await addQRCode(doc, textRenderer, voucher)
  addLeafs(doc, voucher)
  addInstructions(textRenderer, voucher)
  addContact(textRenderer, voucher)
  if (voucher.voucherType === 'gift-card') {
    addDisclaimerVoucher(textRenderer, voucher)
  } else {
    addDisclaimerDiscount(textRenderer, voucher)
  }

  if (voucher.isExample) {
    addExampleText(doc)
  }
  return doc.save(`Sisterhood-Massagen_${VoucherApplication.getNameShort(voucher)}_${VoucherApplication.formatYearAndIndex(voucher.voucherYearIndex, voucher.voucherYear)}.pdf`)
}

export const PdfRender = {
  create
}
